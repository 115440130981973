import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/slices/auth";
import LoginBenefits from "@/components/OffCanvas/Login/LoginOffCanvasItems/LoginBenefits";
import LoginTitle from "@/components/OffCanvas/Login/LoginOffCanvasItems/LoginTitle";

const LoginBody = dynamic(() => import("@/components/OffCanvas/Login/LoginOffCanvasItems/LoginBody"));

const RedirectLogin = (props: { children: ReactElement }) => {
  const user = useSelector(selectUser);
  const router = useRouter();

  useEffect(() => {
    // TODO: look into this
    if (user.id) {
      const qp = new URLSearchParams(router.asPath.split("?")[1]);
      router.replace(qp.get("url") || (router.query.url as string) || "/");
    }
  }, [user]);

  return props.children;
}

const Login = () => {
  const description = ["Ask Unlimited Doubts", "Video Solutions in multiple languages (including Hindi)", "Video Lectures by Experts", "Free PDFs (Previous Year Papers, Book Solutions, and many more)", "Attend Special Counselling Seminars for IIT-JEE, NEET and Board Exams"];

  return (
    <RedirectLogin>
      <div className="body-content min-h-screen-1/2  flex flex-col justify-center items-center">
        <h1>Login to Doubtnut</h1>
        <div className="flex flex-wrap mt-4">
          <div className="bg-secondary lg:px-4 lg:basis-1/2 grow ">
            <LoginBenefits items={description}/>
          </div>
          <div className="lg:basis-1/2 lg:pl-4 grow flex flex-col">
            <LoginTitle excludeTitles={["Login"]}/>
            <LoginBody />
          </div>
        </div>
      </div>
    </RedirectLogin>
  );
}

export const getServerSideProps = (ctx: any) => {
  const resolvedUrl = ctx.resolvedUrl;
  return {
    props: {
      resolvedUrl
    }
  };
}

export default Login;